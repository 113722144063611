import React, { Component } from "react";

class PageOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // skinToneModels: {},
            skinTone: -1
        };
        // this.initialize = this.initialize.bind(this);
        this.setSkinTone = this.setSkinTone.bind(this);
        this.modelClickHandler = this.modelClickHandler.bind(this);
    }

    async componentDidMount() {
        // this.initialize();
        // await fetch(this.props.endpoint + '/qa/get_models_per_skin_tone', {
        //     method: 'GET',
        //     headers: this.getAuthenticationHeader(),
        // }).then(response => response.json()).then((body) => {
        //     var success = body.success;
        //     if (success) {
        //         let models = {}
        //         models["male"] = body.male;
        //         models["female"] = body.female;
        //         this.setState({
        //             skinToneModels: models,
        //         })
        //     }
        // })
    };

    getAuthenticationHeader(json=false) {
        var pbkdf2 = require('pbkdf2')
        let time =  parseInt(Date.now()/1000);
        var derivedKey = pbkdf2.pbkdf2Sync(time.toString(), this.props.login, 11, 32, 'sha256');
        derivedKey = derivedKey.toString('hex');
        if (json) {
            return  new Headers({
                "client": this.props.client,
                "one_time_code": derivedKey,
                "timestamp": time,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            })
        } else {
            return  new Headers({
                "client": this.props.client,
                "one_time_code": derivedKey,
                "timestamp": time,
            })
        }
    }

    setSkinTone(e) {
        let index = parseInt(e.target.id);
        this.props.setSkinTone((index == this.props.skinTone) ? -1 : index);
    }

    modelClickHandler(e) {
        let modelName = e.target.name;
        this.props.modelClickHandler(modelName);
    }

    render() {
        let gender = this.props.gender;
        let skinToneModels = this.props.skinToneModels[gender];
        let demoModels = [];

        if (skinToneModels != undefined && skinToneModels.length > 0) {
            if (this.props.skinTone != -1) {
                demoModels = skinToneModels[this.props.skinTone];
            } else {
                for (let i=0;i<6;i++){
                    if (i==2 || i==4 || i==5)  {
                        demoModels.push(skinToneModels[i][0]);
                    }
                }
            }
        }

        let ids = ["2", "4", "5"]
        const model2index = ["one", "two", "three", "four", "five", "six"];

        return([
            <div className={"frame " + ((this.props.mobile) ? "mobile ":"")} id="reveryFrame" name="https://revery.ai">
                <div className={"titleDiv " + ((this.props.mobile) ? "mobile ":"")}>
                    <div className={"title " + ((this.props.mobile) ? "mobile ":"")}>
                        Customize your model
                    </div>
                </div>
                <div className={"customizeOptionsContainer "  + ((this.props.mobile) ? "mobile ":"")}>
                    <div className="modelGenderContainer">
                        <p className={"genderText"}>Gender</p>
                        <div className="chooseGenderButtons">
                            <button name="male" className={"genderButton male" + (gender === 'male' ? " selected" : "")}
                                    onClick={(gender === 'male') ? "" : this.props.chooseGender}>M</button>
                            <button name="female"
                                    className={"genderButton female" + (gender === 'female' ? " selected" : "")}
                                    onClick={gender === 'female' ? "" : this.props.chooseGender}>F</button>
                        </div>
                    </div>
                    <div className={"skinToneContainer"}>
                        <p className={"skinToneText"}>Skin tone</p>
                        <div className="skinToneOptions">
                            {
                                ids.map((id, i) => (
                                    <div className="skinTone">
                                        <button id={id} className={"skinToneButton " + model2index[id] + (this.props.skinTone === parseInt(id) ? " selected" : "")}
                                                onClick={this.setSkinTone}></button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className={"modelsFrame " + ((this.props.mobile) ? "mobile ":"")}>
                    <p className={"modelsText"}> Select model </p>
                    <div className={"modelsOptions"}>
                        {
                            !this.props.isPageLoading ? demoModels.slice(0,4).map((model_id) => (
                                <div className={"modelsImg " + ((this.props.mobile) ? "mobile ":"") + ((model_id == this.props.model_id) ? "selected":"")}
                                     name={model_id} onClick={this.modelClickHandler}>
                                    <img name={model_id}
                                         // src={"https://tryon-data-bucket.s3.us-east-2.amazonaws.com/farfetch/farfetch_images/" + model_id.replace("model", "farfetch") + "/ou.jpg"}/>
                                         src={"https://media.revery.ai/generated_model_image/" + this.props.skinToneModelFilesDict[model_id] + ".png"}/>
                                </div>
                            )) : ""
                        }
                    </div>
                </div>
            </div>
        ])
    }
}

export default PageOne;
