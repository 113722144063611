import React from 'react';
import ReactDOM, {findDOMNode} from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// must comment out before exporting
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('revery')
);

window.cartTrigger = function(quantity) {
   window.$(document.body).trigger('shopping.bag.updated', {
       quantity: quantity
   });
}

window.fastCheckoutTrigger = function(productIDs) {
   window.$(document.body).trigger('fitting.fast_order', [productIDs]);
}

window.reveryLoadWidget = function (user_id) {
  window.user_id = user_id;
    ReactDOM.render(
      <App />,
        document.getElementById('revery')
    );
};

window.enabled = true;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
