export const localization = {
	"en": {
		"choice_clothing": "Choice of clothing parameter",
		"count": "Count",
		"error": "Please choose more brands",
		"select_gender": "Select gender",
		"select_category": "Select category",
		"men": "Male",
		"women": "Female",
		"select_size": "Select size",
		"size": "size",
		"top": "Top",
		"bottom": "Bottom",
		"outerwear": "Outerwear",
		"dress": "Dress",
		"typing": "Start typing or select from list",
		"select_brands": "Select Brands",
		"to_image_selection": "To Image Selection",
		"any_size": "Any size",
		"image_selection": "Image Selection",
		"filter": "Filter by size and brand",
		"view_saved": "View saved",
		"buy": "Buy items",
		"checkout": "Checkout",
		"delete": "Delete",
		"replace": "Replace",
		"look": "Look",
		"add_cart": "Add to cart",
		"one_click": "One Click Buy",
		"return": "Return",
		"total": "Total",
		"skintone": "Choose skin tone:",
		"virtual_store": "Virtual Store",
		"virtual_dress_room": "Virtual Dressing Room",
		"powered_by": "Powered by",
		"bags": "Bags"
	},
	"ru": {
		"choice_clothing": "Выбор параметров одежды",
		"count": "Всего",
		"error": "К сожалению, по вашим критериям нет товаров, выберите, пожалуйста больше брендов.",
		"select_gender": "Выберите пол",
		"men": "Мужчинам",
		"women": "Женщинам",
		"select_size": "Выберите размер",
		"select_category": "Выбрать категорию",
		"size": "Размер",
		"top": "верх",
		"bottom": "низ",
		"outerwear": "ВЕРХНЯЯ ОДЕЖДА",
		"dress": "ПЛАТЬЯ",
		"typing": "Начните вводить или выберите из списка",
		"select_brands": "Выберите бренды",
		"to_image_selection": "К ПОДБОРУ ОБРАЗА",
		"any_size": "любой",
		"image_selection": "Подбор образа",
		"filter": "Редактировать",
		"buy": "КУПИТЬ ВЕСЬ ОБРАЗ",
		"checkout": "Оформление заказа",
		"delete": "Удалить",
		"replace": "Заменить",
		"look": "Посмотреть",
		"add_cart": "ДОБАВИТЬ В КОРЗИНУ",
		"one_click": "Купить в 1 клик",
		"return": "Вернуться",
		"total": "ИТОГО",
		"skintone": "Выберите оттенок кожи:",
		"virtual_store": "Виртуальный магазин",
		"powered_by": "Разработано",
		"bags": "Сумки"
	},
	"uk": {
		"choice_clothing": "Вибір параметрів одягу",
		"count": "Всього",
		"error": "На жаль, за вашими критеріями немає товарів, виберіть, будь ласка більше брендів.",
		"select_gender": "Оберіть стать",
		"men": "Чоловікам",
		"women": "Жінкам",
		"select_size": "Оберіть розмір",
		"select_category": "Вибрати категорію",
		"size": "Розмір",
		"top": "Верх",
		"bottom": "низ",
		"outerwear": "ВЕРХНІЙ ОДЯГ",
		"dress": "СУКНІ",
		"typing": "Почніть вводити або оберіть зі списку",
		"select_brands": "Оберіть бренди",
		"to_image_selection": "ДО ПОДБОРУ ОБРАЗУ",
		"any_size": "будь-який",
		"image_selection": "Підбір образу",
		"filter": "Редагувати",
		"buy": "КУПИТИ ВЕСЬ ОБРАЗ",
		"checkout": "Оформлення замовлення",
		"delete": "Видалити",
		"replace": "Замінити",
		"look": "Подивитися",
		"add_cart": "ДОДАТИ В КОШИК",
		"one_click": "Купити в 1 клік",
		"return": "Повернутися",
		"total": "РАЗОМ",
		"skintone": "Виберіть тон шкіри:",
		"virtual_store": "Віртуальний магазин",
		"powered_by": "Розробленно",
		"bags": "Сумки"
	}
}