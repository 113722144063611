export const chunk = (str)=> {
      if (str === "Out of stock"){
          return str
      }
    var ret = [];
    var i;
    var n = 3;

    if (str.length > 1){
        var cents = str.substr(str.length-2, str.length);
        str = str.substr(0, str.length-2);
    }

    for(i = str.length-3; i >= -2; i -= n) {
        if (i < 0){
            ret.unshift(str.substr(0, n+i))
        }
        else{
            ret.unshift(str.substr(i, n))
        }
    }
    return ret.join(" ");
};

export const truncate = (str) => {
      var desired_length = 24;
      if (str.length > desired_length) {
          return str.substr(0,desired_length); // + "...";
      }
      return str;
}

export const fromHtmlEntities = (rawStr) => {
    var retStr = rawStr.replace(/[\u00A0-\u9999<>\&]/g, function(i) {
       return '&#'+i.charCodeAt(0)+';';
    })
    return retStr;
}

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
