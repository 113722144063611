import React, {Component} from "react";
import ReactGA from 'react-ga';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import {localization} from "./localization";
import {truncate} from "./utils";
import './frameStyle.css';
import PageOne from "./components/PageOne"
import Segments from "./components/Segments"

const heart = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/heart.png";
const heart_fill = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/heart_fill.png";

class App extends Component {
    constructor(props) {
        /**
         * isLoading - flag if product page is loading
         * isLoadingImg - flag if generated img is loading
         * wearingProducts - list of garment ids of the current generated image
         * wearingCategories - list of categories for current generated image
         * products - dictionary of all garment ids for default products
         * uploadedProducts - dictionary of all garment ids for uploaded products
         * itemUrl - dictionary of garment id to file url of garment img on AWS
         * itemClass - dictionary of garment id to html class (not differentiated right now)
         * itemCategories - dictionary of garment id to garment category
         * models - list of model ids
         * modelUrl - dictionary of model id to file url of model img on AWS
         * modelFile - img file of generated image on AWS
         * segment - category of garment being viewed in segmented control
         * gender - selected gender
         * uploadPercentage - percentage of upload
         */
        super(props);
        this.production = false;
        this.endpoint = "https://api.revery.ai";
        this.visitorID = "123456";
        this.name = "";
        this.client = "";
        this.login = "";
        this.state = {
            symbolID: "",
            String: localization.en,
            width: props.width,
            height: props.height,
            progress: 0,
            isLoadingCover: false,
            mobile: false,
            prompt: false,
            firstLoad: true,
            step: 2, //"1" or "2"

            isLoading: true,
            isLoadingImg: true,
            isLoadingImgCustomize: false,
            isPageLoading: true,
            productIsLoading: false,
            loadingAllProducts: false,
            productIsLoadingFirst: false,
            availableOutfit: true,
            addingCart: false,

            wearingProducts: [],
            wearingCategories: [],

            products: {
                "male": {"tops": [], "bottoms": [], "outerwear": [], "allbody": [], "bags": []},
                "female": {"tops": [], "bottoms": [], "outerwear": [], "allbody": [], "bags": []}
            },

            itemUrl: {},
            itemClass: {},
            itemCategories: {},
            itemName: {},
            itemBrand: {},
            itemPrice: {},
            itemPage: {},
            product_id: "",

            segment: "tops",
            gender: "female",
            page: {"tops": 0, "bottoms": 0, "outerwear": 0, "allbody": 0, "bags": 0},
            savedPage: {"tops": -1, "bottoms": -1, "outerwear": -1, "allbody": -1, "bags": -1},
            scrollCoords: {"tops": 0, "bottoms": 0, "outerwear": 0, "allbody": 0, "bags": 0, "background": 0, "shoes": 0, "faces": 0},
            allScrollCoords: {"tops": 0, "bottoms": 0, "outerwear": 0, "allbody": 0, "bags": 0, "background": 0, "shoes": 0, "faces": 0},
            models: [],
            skinToneModels: {"male":[], "female":[]},
            skinToneModelFilesDict: {},
            skinTone: -1,
            moreModels: [],
            moreModelsModelFiles: [],
            shoeModels: {"male":[],"female":[]},
            modelShoeUrls: {"male":[],"female":[]},
            faceIds: {"male":[],"female":[]},
            modelUrl: {},
            modelFile: "",
            displayFile: "",
            backgrounds: [
                // https://media.revery.ai/composite_images/
                "background_1.jpg",
                "background_2.jpg",
                "background_3.jpg",
                "background_4.jpg",
                "background_5.jpg",
                "arpad-spodni-kF0fXa7GTj0-unsplash.jpg",
                "charles-black-F7HGqkkMYAU-unsplash.jpg",
                "charlie-green-yAMPbrx-vQE-unsplash_cropped.jpg",
                "copernico-p_kICQCOM4s-unsplash.jpg",
                // "daniel-tseng-73lmMKhi2U8-unsplash.jpg",
                // "derick-mckinney-X1WvcGuvuSY-unsplash.jpg",
                "erol-ahmed-FTy5VSGIfiQ-unsplash_cropped.jpg",
                "hutomo-abrianto-9mPl0Zo7_gQ-unsplash_cropped.jpg",
                "jason-wang-5MG8cQbw-T8-unsplash.jpg",
                "jose-losada-DyFjxmHt3Es-unsplash_cropped.jpg",
                // "judith-girard-marczak-wH8jhljxb9w-unsplash.jpg",
                "kaveen-me-NFbwes_e-jI-unsplash_cropped.jpg",
                "laura-davidson-QBAH4IldaZY-unsplash.jpg",
                "mickey-o-neil-xL66l--msXU-unsplash.jpg",
                "minh-pham-OtXADkUh3-I-unsplash.jpg",
                "nastuh-abootalebi-ZtC4_rPCRXA-unsplash.jpg",
                "orlova-maria-b37mDyPzdJM-unsplash_cropped.jpg",
                "paul-hanaoka-Dl6H4_nzBpE-unsplash.jpg",
                "philipp-berndt-5i0GnoTTjSE-unsplash_cropped.jpg",
                "richard-bell-6F-TKRhr2y0-unsplash_cropped.jpg",
                "sonnie-hiles-iANAdaNu7mg-unsplash_cropped.jpg",
                // "toa-heftiba-FV3GConVSss-unsplash.jpg"
            ],
            background_url: "",
            toggleModels: false,
            toggleGarments: false,
            toggleTucked: false,
            toggleScrollUp: false,

            brands: [], // all brands
            productCount: {},

            scrolled: false, // for checking if scrolled in analytics
            savedProducts: {
                "male": {"tops": [], "bottoms": [], "outerwear": [], "allbody": [], "bags": []},
                "female": {"tops": [], "bottoms": [], "outerwear": [], "allbody": [], "bags": []}
            },
            allSavedProducts: [], // list of all saved products from user
            showSaved: false,
            shoes_id: null,
            face_id: null,
            // version: "v2.10.0.4"
            version: "free_layering"
        };

        this.initialize = this.initialize.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.tracking = this.tracking.bind(this);
        this.trackProductClick = this.trackProductClick.bind(this);
        this.renderCover = this.renderCover.bind(this);
        
        this.authenticate = this.authenticate.bind(this);
        this.submitAuthentication = this.submitAuthentication.bind(this);
        this.renderAuthentication = this.renderAuthentication.bind(this);

        this.setLoadingFalse = this.setLoadingFalse.bind(this);
        this.setAllProductsLoadedFalse = this.setAllProductsLoadedFalse.bind(this);
        this.setLoadingSizeFalse = this.setLoadingSizeFalse.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

        this.fetchShoes = this.fetchShoes.bind(this);
        this.fetchNewModelWithProducts = this.fetchNewModelWithProducts.bind(this);
        this.fetchNewTryonModelImage = this.fetchNewTryonModelImage.bind(this);
        this.fetchProducts = this.fetchProducts.bind(this);
        this.fetchNewPage = this.fetchNewPage.bind(this);

        this.segmentControl = this.segmentControl.bind(this);
        this.chooseGender = this.chooseGender.bind(this);
        this.toggleModels = this.toggleModels.bind(this);
        this.toggleGarments = this.toggleGarments.bind(this);
        this.toggleWidget = this.toggleWidget.bind(this);
        this.toggleTucked = this.toggleTucked.bind(this);
        this.toggleScrollUpFalse = this.toggleScrollUpFalse.bind(this);

        this.garmentClickHandler = this.garmentClickHandler.bind(this);
        this.removeOuterwear = this.removeOuterwear.bind(this);
        this.modelClickHandler = this.modelClickHandler.bind(this);
        this.shoeClickHandler = this.shoeClickHandler.bind(this);
        this.faceClickHandler = this.faceClickHandler.bind(this);
        this.backgroundClickHandler = this.backgroundClickHandler.bind(this);
        this.deleteWearingGarment = this.deleteWearingGarment.bind(this);
        this.setSkinTone = this.setSkinTone.bind(this);

        this.zoom = this.zoom.bind(this);
        this.zoomLeave = this.zoomLeave.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.backgroundImageGenerator = this.backgroundImageGenerator.bind(this);
        
        this.renderProducts = this.renderProducts.bind(this);
        this.renderBackgrounds = this.renderBackgrounds.bind(this);
        this.renderImg = this.renderImg.bind(this);

        this.renderGarmentsFrame = this.renderGarmentsFrame.bind(this);
        this.renderGarmentsFrameMobile = this.renderGarmentsFrameMobile.bind(this);
        this.renderMobile = this.renderMobile.bind(this);
        this.renderPage = this.renderPage.bind(this);

        this.gotoPageOne = this.gotoPageOne.bind(this);
        window.toggleWidget = this.toggleWidget;
    }

    async componentDidMount() {
        await this.authenticate("demo", "name",
            "jgiorjewnvdsap1935njkdnsaklcmeoiw1n00n1nu3njo1nlfndjno0i0jmklmjncdsa");
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    };

    async initialize() {
        let url = window.location.href;
        let language = url.split("symbol.ua/")[1]
        if (language !== undefined) {
            language = language.split("/")[0];
        }
        if (language === "en" || language === "uk") {
            this.setState({String: localization[language]})
        }

        const fp = await FingerprintJS.load();

        // The FingerprintJS agent is ready.
        // Get a visitor identifier when you'd like to.
        const result = await fp.get();

        // This is the visitor identifier:
        const visitorId = result.visitorId;
        this.visitorID = visitorId;
        this.fpVisitorID = visitorId;

        if (this.production) {
            this.mixpanel = require('mixpanel-browser');
            this.mixpanel.init("6a9b34a8817408ef1dbacfddcbe76ec0");
            this.mixpanel.track("Initialize", {"location": "Webpage"});
            this.mixpanel.identify(this.visitorID);

            var symbolId = "";

            this.mixpanel.people.set({
                "USER_ID": this.visitorID,
                "SYMBOL_ID": symbolId
            })

            this.setState({
                symbolID: symbolId
            })
        }

        let trackingId = ""
        if (this.production === true) {
            trackingId = "UA-189706052-2";
        } else {
            trackingId = "UA-189706052-1";
        }
        if (this.production) {
            if (window.ga !== "function") {
                ReactGA.initialize(trackingId);
                ReactGA.set({userId: window.user_id})
            }
            window.ga('create', trackingId, 'auto', 'ReveryTracker',
                {userId: this.visitorID});
            window.ga('ReveryTracker.set', 'userId', this.visitorID);
            window.ga('ReveryTracker.send', 'event', 'Revery.Initialize', 'Webpage changing room initialization');
        }

        this.fetchProducts();
        this.fetchShoes();
        this.fetchFaces();

        await fetch(this.endpoint + '/interactivedemo_hr/get_models_per_skin_tone', {
            method: 'GET',
            headers: this.getAuthenticationHeader(),
        }).then(response => response.json()).then((body) => {
            var success = body.success;
            if (success) {
                let models = {}
                models["male"] = body.male;
                models["female"] = body.female;
                this.setState({
                    skinToneModels: models,
                    skinToneModelFilesDict: body.model_files
                })
            }
        })

        var interval = setInterval(() => {
            var index = this.state.progress;
            var renderCover = this.state.isLoadingCover;
            if (index >= 100) {
                renderCover = false;
            } else {
                if (index >= 40 && ((this.state.isLoading) || (this.state.isPageLoading))) {
                    (index > 95) ? index += 0 : index += 0.2;
                } else {
                    (index >= 40) ? index += 2 : index += 1;
                }
            }
            this.setState({
                progress: index,
                isLoadingCover: renderCover
            })
        }, 30);

        this.setState({
            interval: interval,
        })

        // if (screenfull.isEnabled) {
        //     screenfull.request();
        // }
    }

    getAuthenticationHeader(json=false) {
        var pbkdf2 = require('pbkdf2')
        let time =  parseInt(Date.now()/1000);
        var derivedKey = pbkdf2.pbkdf2Sync(this.login, time.toString(), 128, 32, 'sha256');
        derivedKey = derivedKey.toString('hex');
        if (json) {
            return  new Headers({
                "client": this.client,
                "one_time_code": derivedKey,
                "timestamp": time,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            })
        } else {
            return  new Headers({
                "client": this.client,
                "one_time_code": derivedKey,
                "timestamp": time,
            })
        }
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let frame = document.getElementById("reveryFrame");
        if (window.innerWidth < 767) {
            this.setState({mobile: true});
            frame.style.height = "100vh"
            frame.style.width = "100vw"
        } else {
            this.setState({mobile: false});
            this.setState({width: window.innerWidth, height: window.innerHeight});
            if (window.innerWidth/window.innerHeight > 1536/1024) {
                if (window.innerHeight < 600) {
                    frame.style.height = "600px"
                    frame.style.width = (600*1536/1024).toString() + "px"
                } else {
                    frame.style.height = "100vh"
                    frame.style.width = (100*1536/1024).toString() + "vh"
                }
            } else {
                frame.style.width = "100vw"
                frame.style.height = (100*1024/1536).toString() + "vw"
            }
        }
    }

    componentDidUpdate() {
        if (this.state.productIsLoading) {
            if (this.state.step !== 2) {
                this.setState({
                    productIsLoading: false
                });
            } else {
                let diff = document.getElementById("garmentOptions").scrollTop - this.state.scrollCoords[this.state.segment];
                if (diff > -100 && diff < 100) {
                    // var garment_id = this.state.wearingProducts[this.state.wearingCategories.indexOf(this.state.segment)]
                    // if(document.getElementById("garmentOptions").scrollTop === document.getElementById("revery_" + garment_id).offsetTop){
                    this.setState({
                        productIsLoading: false
                    });
                }
                document.getElementById("garmentOptions").scrollTo(0, this.state.scrollCoords[this.state.segment]);
                // document.getElementById("garmentOptions").scrollTo(0,document.getElementById("revery_" + garment_id));
            }
        }
    }

    async authenticate(client, name, login) {
        var pbkdf2 = require('pbkdf2')
        let time =  Date.now()/1000;
        // var derivedKey = pbkdf2.pbkdf2Sync(time.toString(), "89yniliw9svoy58ekugoy8w945y0n3b4y03voijtm0w8v5suy9eu96yjydr4vv4jm5ye", 11, 32, 'sha256');
        var derivedKey = pbkdf2.pbkdf2Sync(login, time.toString(), 128, 32, 'sha256');
        derivedKey = derivedKey.toString('hex');
        // console.log(Date.now()/1000, Date.now(), client, derivedKey)
        const data = JSON.stringify({
            "site": client,
            "one_time_code": derivedKey,
            "timestamp": time,
            "buffer_time": 200,
        });
        await fetch(
            this.endpoint + '/qa_authenticate',
            {
                method: 'POST',
                headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
                body: data,
            }).then(response => response.json()).then((body) => {
            if (!body.success) {
                alert("Invalid credentials")
            } else {
                document.cookie = "revery_secret_key=" + login;
                document.cookie = "revery_name=" + name;
                document.cookie = "revery_client=" + client;

                this.name = name;
                this.client = client;
                this.login = login;

                this.setState({authenticated: true}, ()=>{
                    this.initialize();
                })
            }
        })
    }

    submitAuthentication(e) {
        e.preventDefault();
        let client = document.getElementById("qaclient").value;
        let name = document.getElementById("qaname").value;
        let secret_key = document.getElementById("qakey").value;

        this.authenticate(client, name, secret_key);
    }

    tracking(gaName, gaDescription, mpName, mpLocation, mpAttributeName = undefined, mpAttributeValue = undefined) {
        // console.log({[gaName]: gaDescription, "location": mpLocation, [mpAttributeName]: mpAttributeValue})
        if (this.production) {
            window.ga('ReveryTracker.send', 'event', gaName, gaDescription);
            if (mpAttributeName !== undefined) {
                this.mixpanel.track(mpName, {"location": mpLocation});
            } else {
                this.mixpanel.track(mpName, {"location": mpLocation, [mpAttributeName]: mpAttributeValue});
            }
        }
    }

    async fetchShoes(){
        await fetch(this.endpoint + '/interactivedemo_hr/get_selected_shoes_with_paging?gender=female&page_size=50', {
            method: 'GET',
            headers: this.getAuthenticationHeader(),
        }).then(response => response.json()).then((body) => {
            let shoe_model_ids = Object.assign({},this.state.shoeModels);
            let shoe_image_urls = Object.assign({},this.state.modelShoeUrls);
            console.log(body.shoe_s3_urls);
            body.shoe_model_ids.forEach((model_id,i) => {
                shoe_image_urls[model_id.replace("farfetch", "model")] = "https://media.revery.ai/" + body.shoe_s3_urls[i];
            })
            shoe_model_ids["female"] = body.shoe_model_ids.map((model_id) => model_id.replace("farfetch", "model"));
            this.setState({
                shoeModels: shoe_model_ids,
                modelShoeUrls: shoe_image_urls
            })
        })

        fetch(this.endpoint + '/interactivedemo_hr/get_selected_shoes_with_paging?gender=male&page_size=50', {
            method: 'GET',
            headers: this.getAuthenticationHeader(),
        }).then(response => response.json()).then((body) => {
            let shoe_model_ids = Object.assign({},this.state.shoeModels);
            let shoe_image_urls = Object.assign({},this.state.modelShoeUrls);
            body.shoe_model_ids.forEach((model_id,i) => {
                shoe_image_urls[model_id.replace("farfetch", "model")] = "https://media.revery.ai/" + body.shoe_s3_urls[i];
            })
            shoe_model_ids["male"] = body.shoe_model_ids.map((model_id) => model_id.replace("farfetch", "model"));
            this.setState({
                shoeModels: shoe_model_ids,
                modelShoeUrls: shoe_image_urls
            })
        })
    }

    async fetchFaces(){
        await fetch(this.endpoint + '/interactivedemo_hr/get_selected_revery_faces?gender=female', {
            method: 'GET',
            headers: this.getAuthenticationHeader(),
        }).then(response => response.json()).then((body) => {
            let faceIds = Object.assign({},this.state.faceIds);
            faceIds["female"] = body.faces;
            this.setState({
                faceIds: faceIds
            })
        })

        fetch(this.endpoint + '/interactivedemo_hr/get_selected_revery_faces?gender=male', {
            method: 'GET',
            headers: this.getAuthenticationHeader(),
        }).then(response => response.json()).then((body) => {
            let faceIds = Object.assign({},this.state.faceIds);
            faceIds["male"] = body.faces;
            this.setState({
                faceIds: faceIds
            })
        })
    }

    fetchProducts(render = true) {
        let retProductsDict = Object.assign({}, this.state.products);
        let retSavedProductsDict = Object.assign({}, this.state.savedProducts);

        if (this.state.firstLoad) {
            this.fetchNewModelWithProducts();
        }

        this.setState({
            products: retProductsDict,
            savedProducts: retSavedProductsDict,
            page: {"tops": 0, "bottoms": 0, "outerwear": 0, "allbody": 0, "bags": 0},
            scrollCoords: {"tops": 0, "bottoms": 0, "outerwear": 0, "allbody": 0, "bags": 0, "background": 0, "shoes": 0, "faces": 0},
            allScrollCoords: {"tops": 0, "bottoms": 0, "outerwear": 0, "allbody": 0, "bags": 0, "background": 0, "shoes": 0, "faces": 0},
        }, () => {
            render ? this.fetchNewPage(true, true) : this.fetchNewPage(false, false, "tops");
        });
    }

    async fetchNewModelWithProducts() {
        /**
         * Fetch random generated image, and photoshoot models to select from.
         **/
        this.setState({isLoadingImg: true});
        await fetch(this.endpoint + '/interactivedemo_hr/get_default_model?version=' + this.state.version +
            "&client=" + this.client + "&gender=" + this.state.gender, {
            method: 'GET',
            headers: this.getAuthenticationHeader(),
        }).then(response => response.json()).then((body) => {
            var success = body.success;
            if (success) {
                body = body.model_metadata;
                let gender = body.gender;
                let itemClasses = Object.assign({}, this.state.itemClass);
                let itemUrl = Object.assign({}, this.state.itemUrl);
                let itemPage = Object.assign({}, this.state.itemPage);
                let itemName = Object.assign({}, this.state.itemName);
                let itemBrand = Object.assign({}, this.state.itemBrand);
                let itemPrice = Object.assign({}, this.state.itemPrice);
                let productsDict = Object.assign({}, this.state.products[gender]);
                let itemCategories = Object.assign({}, this.state.itemCategories);

                this.setState({
                    segment: "tops"
                });
                body.product_ids.forEach((item, i) => {
                    if (!productsDict[body.product_categories[i]].includes(item)) {
                        itemClasses[item] = "productImgContainer";
                        itemUrl[item] = body.products[item].image_urls.product_image;
                        itemPage[item] = body.products[item].url;
                        itemName[item] = body.products[item].name;
                        itemBrand[item] = body.products[item].brand;
                        productsDict[body.product_categories[i]].unshift(item);
                        itemCategories[item] = body.product_categories[i];
                    } else {
                        productsDict[body.product_categories[i]].splice(productsDict[body.product_categories[i]].indexOf(item), 1);
                        productsDict[body.product_categories[i]].unshift(item);
                    }
                });

                let retProductsDict = Object.assign({}, this.state.products);
                retProductsDict[gender] = productsDict;
                if (JSON.stringify(body.product_ids) == JSON.stringify(this.state.wearingProducts)) {
                    this.setState({isLoadingImg: false});
                }

                let shoe_model_ids = Object.assign({},this.state.shoeModels);
                let index = shoe_model_ids[gender].indexOf(body.shoes_id);
                shoe_model_ids[gender].splice(index, 1);
                shoe_model_ids[gender].unshift(body.shoes_id);

                this.setState({
                    // product_id: garment_id_string,
                    shoe_model_ids: shoe_model_ids,
                    shoes_id: body.shoes_id,
                    wearingCategories: body.product_categories,
                    wearingProducts: body.product_ids,
                    model_id: body.model_id,
                    modelFile: body.model_file,
                    displayFile: "https://media.revery.ai/generated_model_image/" + body.model_file + ".png",
                    gender: gender,
                    itemCategories: itemCategories,
                    itemClass: itemClasses,
                    itemUrl: itemUrl,
                    itemName: itemName,
                    itemBrand: itemBrand,
                    itemPrice: itemPrice,
                    products: retProductsDict,
                    itemPage: itemPage,
                    availableOutfit: true,
                    firstLoad: false
                });
            }
        });
    }

    async fetchNewPage(all = true, fetchOutfit = false, garmentType = "none", showSaved = this.state.showSaved, scrolled = false) {
        // Add new page to products
        this.setState({
            isLoading: true
        })
        if (all) {
            var garment_types = ['tops', 'bottoms', 'outerwear', 'allbody'];
        } else {
            var garment_types = (garmentType === "none") ? [this.state.segment] : [garmentType];
        }
        let productsDict = Object.assign({}, this.state.products[this.state.gender]);
        for (var category of garment_types) {
            var filter_by_user_id = ''
            if (showSaved) {
                filter_by_user_id = "&filter_by_user_id=" + this.visitorID;
                productsDict = Object.assign({}, this.state.savedProducts[this.state.gender]);
            }

            await fetch(this.endpoint + '/interactivedemo_hr/get_filtered_products?category=' + category + '&gender=' + this.state.gender + '&page=' + this.state.page[category] + '&site_user_id=' + this.state.symbolID + filter_by_user_id + "&enabled=" + 1, {
                method: "GET",
                headers: this.getAuthenticationHeader(),
            }).then(response => response.json()).then((body) => {
                var success = body.success;
                if (success) {
                    let itemClasses = Object.assign({}, this.state.itemClass);
                    let itemUrl = Object.assign({}, this.state.itemUrl);
                    let itemName = Object.assign({}, this.state.itemName);
                    let itemBrand = Object.assign({}, this.state.itemBrand);
                    let itemCategories = Object.assign({}, this.state.itemCategories);
                    let itemPage = Object.assign({}, this.state.itemPage);
                    let itemPrice = Object.assign({}, this.state.itemPrice);
                    let page = Object.assign({}, this.state.page);
                    if (body.products.length < 24 && scrolled) {
                        page[category] -= 1;
                    }

                    body.products.forEach((item, i) => {
                        let category = item.tryon.category;
                        if (!productsDict[category].includes(item.id)) {
                            productsDict[category].push(item.id);
                            itemUrl[item.id] = item.image_urls.product_image;
                            itemClasses[item.id] = "productImgContainer";
                            itemCategories[item.id] = category;
                            itemPage[item.id] = item.url;
                            itemBrand[item.id] = item.brand;
                            itemName[item.id] = item.name;
                            itemPrice[item.id] = item.price;
                        }
                    });

                    let retProductsDict = Object.assign({}, this.state.products);
                    let retSavedProductsDict = Object.assign({}, this.state.savedProducts);

                    if (showSaved) {
                        retSavedProductsDict[this.state.gender] = productsDict;
                    } else {
                        retProductsDict[this.state.gender] = productsDict;
                    }

                    this.setState({
                        isLoading: false,
                        products: retProductsDict,
                        savedProducts: retSavedProductsDict,
                        itemUrl: itemUrl,
                        itemCategories: itemCategories,
                        itemClass: itemClasses,
                        itemPage: itemPage,
                        itemBrand: itemBrand,
                        itemName: itemName,
                        itemPrice: itemPrice,
                        product_id: this.state.products[this.state.gender]["tops"][0],
                        page: page,
                    }, () => {
                        this.setState({
                            firstLoad: false,
                            isPageLoading: false
                        });
                    });

                    if (category === this.state.segment) {
                        this.setState({
                            isLoading: false
                        });
                    }
                }
            });
        }
        this.setState({firstLoad: false})
    }

    fetchNewTryonModelImage(categories, product_ids, model_id) {
        /**
         * Generates an outfit. If outfit does not generate, keep state as current state.
         *
         * Input
         *    categories - list of garment categories
         *    product_ids - list of product ids for garments (all indices match categories)
         *    model_id - model id of photoshoot model to visualize outfit on
         **/

        let oldWearingCategories = this.state.wearingCategories.slice();
        let oldWearingProducts = this.state.wearingProducts.slice();
        let oldModel_id = this.state.model_id;
        let oldModelFile = this.state.modelFile;
        let oldDisplayFile = this.state.displayFile;

        this.setState({
            isLoadingImg: true,
            wearingCategories: categories.slice(),
            wearingProducts: product_ids.slice(),
            model_id: model_id,
            toggleGarments: false
        });

        if (this.state.toggleTucked && categories.includes("tops") && categories.includes("bottoms")){
            let index = categories.indexOf("tops");
            let bottom_index = categories.indexOf("bottoms");
            let product_id = product_ids[index];

            categories.splice(index, 1);
            product_ids.splice(index, 1);

            categories.splice(bottom_index, 0, "tops");
            product_ids.splice(bottom_index, 0, product_id);
        }

        let data = "";
        data = JSON.stringify({
            "categories": categories,
            "version": this.state.version,
            "product_ids": product_ids,
            "model_id": model_id,
            "site_user_id": this.state.symbolID,
            "tuck_in": this.state.toggleTucked,
            "shoes_id": this.state.shoes_id,
            "face_id": this.state.face_id,
            "client": this.client,
            "intermediate_debug": false
        });

        fetch(
            this.endpoint + '/interactivedemo_hr/create_tryon_for_products',
            {
                method: 'POST',
                headers: this.getAuthenticationHeader(true),
                body: data
            })
            .then(response => response.json())
            .then(body => {
                console.log(body);
                body = body.model_metadata;
                this.tracking('Revery.TryOn', 'Webpage generate try on image on changing room', "TryOn", "Webpage", "modelFile", body["model_file"]);

                if(this.state.background_url !== ""){
                    this.backgroundImageGenerator(body["model_file"], this.state.background_url)
                }
                else {
                    this.setState({
                        modelFile: body["model_file"],
                        displayFile: "https://media.revery.ai/generated_model_image/" + body["model_file"] + ".png",
                        isLoadingImg: false, //TODO keep if loading issues persist
                    });
                }
                this.setState({isLoadingImgCustomize: false});
            }).catch((error) => {
                this.setState({
                    modelFile: oldModelFile,
                    displayFile: oldDisplayFile,
                    isLoadingImg: false,
                    isLoadingImgCustomize: false,
                    wearingCategories: oldWearingCategories,
                    wearingProducts: oldWearingProducts,
                    model_id: oldModel_id
                });
            }
        );
    }

    setLoadingFalse() {
        this.setState({
            isLoadingImg: false,
        });
    }

    setLoadingSizeFalse() {
        this.setState({
            loadingSizes: false,
        });
    }

    setAllProductsLoadedFalse(e) {
        var products = this.state.products[this.state.gender][this.state.segment];
        if (products[products.length - 1] === e.target.name) {

            var garmentOptions = document.getElementById("garmentOptions");
            var diff = garmentOptions.scrollTop + garmentOptions.offsetHeight - garmentOptions.scrollHeight

            if (diff > -3 && this.state.loadingAllProducts) {
                garmentOptions.scrollTo(0, garmentOptions.scrollTop - 3);
            }

            this.setState({
                loadingAllProducts: false,
            });
        }
    }

    deleteWearingGarment(e) {
        /**
         * Delete garment from wearingProducts and wearingCategories
         **/
        let garment = e.target.name;
        let wearingProducts = this.state.wearingProducts.slice();
        let wearingCategories = this.state.wearingCategories.slice();
        let index = wearingProducts.indexOf(garment);
        wearingProducts.splice(index, 1);
        wearingCategories.splice(index, 1);

        this.setState(
            {wearingCategories: wearingCategories, wearingProducts: wearingProducts},
            this.fetchNewTryonModelImage(
                wearingCategories,
                wearingProducts,
                this.state.model_id));
    }

    garmentClickHandler(e) {
        /**
         * Handles selecting a new garment to generate. Garment order logic is below.
         **/
        let itemID = e.currentTarget.getAttribute("name");
        if (itemID === undefined || this.state.isLoadingImg || this.state.isLoadingImgCustomize) {
            return
        }

        this.tracking('Revery.ProductTryOn', 'Webpage product try on in changing room', "ProductTryOn", "Webpage", "productID", itemID);

        let category = this.state.itemCategories[itemID];
        let wearingCategories = this.state.wearingCategories.slice();
        let wearingProducts = this.state.wearingProducts.slice();
        let flag = 0;
        let categories = ["tops", "bottoms", "outerwear", "allbody"]
        if ((!wearingProducts.includes(itemID) && categories.includes(category)) || (wearingProducts.includes(itemID) && category =="outerwear")) {
            if (wearingProducts.includes(itemID)) {
                let index = wearingProducts.indexOf(itemID);
                wearingCategories.splice(index, 1);
                wearingProducts.splice(index, 1);
            } else if (wearingCategories.includes(category)) {
                let index = wearingCategories.indexOf(category);
                wearingProducts[index] = itemID;
            } else {
                if (category === "allbody") {
                    if (wearingCategories.includes("tops")) {
                        let index = wearingCategories.indexOf("tops");
                        wearingCategories.splice(index, 1);
                        wearingProducts.splice(index, 1);
                    }
                    if (wearingCategories.includes("bottoms")) {
                        let index = wearingCategories.indexOf("bottoms");
                        wearingCategories.splice(index, 1);
                        wearingProducts.splice(index, 1);
                    }
                    wearingCategories.push(category);
                    wearingProducts.push(itemID);
                } else if (category === "outerwear") {
                    if (wearingCategories.includes("bags")) {
                        let index = wearingCategories.indexOf("bags");
                        wearingCategories.splice(index, 0, category);
                        wearingProducts.splice(index, 0, itemID);
                    } else {
                        wearingCategories.unshift(category);
                        wearingProducts.unshift(itemID);
                    }
                } else if ((wearingCategories.includes("allbody") && (category === 'tops' || category === 'bottoms'))) {
                    let index = wearingCategories.indexOf("allbody");
                    wearingCategories.splice(index, 1);
                    wearingProducts.splice(index, 1);

                    if (category === "tops") {
                        wearingCategories.push("tops")
                        wearingProducts.push(itemID);
                        wearingCategories.push("bottoms")
                        wearingProducts.push(this.state.products[this.state.gender]["bottoms"][0]);
                    } else {
                        wearingCategories.push("tops")
                        wearingProducts.push(this.state.products[this.state.gender]["tops"][0]);
                        wearingCategories.push("bottoms")
                        wearingProducts.push(itemID);
                    }
                } else if (category === "bottoms") {
                    let index = wearingCategories.includes("tops") ? 1 : 0;
                    wearingCategories.splice(index, 0, category);
                    wearingProducts.splice(index, 0, itemID);
                } else if (category === "tops") {
                    wearingCategories.unshift(category);
                    wearingProducts.unshift(itemID);
                } else if (category === "bags") {
                    wearingCategories.push(category);
                    wearingProducts.push(itemID);
                }
            }

            if (flag === 0) {
                this.fetchNewTryonModelImage(
                    wearingCategories,
                    wearingProducts,
                    this.state.model_id
                );
            }
            if (!this.state.productIsLoading) {
                const scrollCoords = Object.assign({}, this.state.scrollCoords);
                scrollCoords[this.state.segment] = document.getElementById("garmentOptions").scrollTop;
                this.setState({scrollCoords: scrollCoords});
            }
        }

    }

    removeOuterwear(e){
        let wearingCategories = this.state.wearingCategories.slice();
        let wearingProducts = this.state.wearingProducts.slice();
        if (wearingCategories.includes("outerwear")) {
            let index = wearingCategories.indexOf("outerwear");
            wearingCategories.splice(index, 1);
            wearingProducts.splice(index, 1);
            this.fetchNewTryonModelImage(
                wearingCategories,
                wearingProducts,
                this.state.model_id
            );
        }
    }


    setSkinTone(skinTone) {
        this.setState({skinTone: skinTone})
    }

    async modelClickHandler(modelName) {
        if (!this.state.isPageLoading) {
            await this.setState({step: 2}, ()=>{
                if (modelName !== this.state.model_id) {
                    this.setState({
                        isLoadingImgCustomize: true,
                        face_id: null
                    }, ()=>{
                        this.fetchNewTryonModelImage(
                            this.state.wearingCategories,
                            this.state.wearingProducts,
                            modelName);
                    });
                    this.updateWindowDimensions();
                }
            })
        }
    }

    shoeClickHandler(e) {
        let modelName = e.target.name;
        this.setState({
            shoes_id: modelName,
        },()=>{
            this.fetchNewTryonModelImage(
                this.state.wearingCategories,
                this.state.wearingProducts,
                this.state.model_id);
        })
    }

    faceClickHandler(e) {
        let face_id = e.target.name;
        this.fetchNewTryonModelImage(
            this.state.wearingCategories,
            this.state.wearingProducts,
            face_id);
    }

    segmentControl(e) {
        if (e.target.id !== "removeOuterwear") {
            this.tracking('Revery.ChangeSegment', 'Webpage switched garment segment on changing room', "ChangeSegment", "Webpage");

            document.getElementById("garmentOptions").scrollTo(0, this.state.scrollCoords[e.currentTarget.id]);
            this.setState({
                segment: e.currentTarget.id,
                productIsLoading: true,
                productIsLoadingFirst: true,
                loadingAllProducts: false,
            });
        }
    }

    toggleScrollUpFalse() {
        this.setState({toggleScrollUp: false})
    }

    handleScroll(e) {
        if (!this.state.scrolled) {
            this.tracking('Revery.Scrolled', 'Webpage scrolled through garments changing room', "Scrolled", "Webpage",);
            this.setState({scrolled: true});
        }

        // //Scroll up swipe up the garments, scroll down swipe down garments
        // const allScrollCoords = Object.assign({}, this.state.allScrollCoords);
        // if (e.target.scrollTop < allScrollCoords[this.state.segment]) {
        //     this.setState({toggleScrollUp: false});
        // } else if (e.target.scrollTop > allScrollCoords[this.state.segment]) {
        //     this.setState({toggleScrollUp: true});
        // }
        // allScrollCoords[this.state.segment] = e.target.scrollTop;
        // this.setState({allScrollCoords: allScrollCoords});

        if (!this.state.wearingCategories.includes(this.state.segment) && !this.state.productIsLoading && !this.state.loadingAllProducts) {
            var scrollCoords = Object.assign({}, this.state.scrollCoords);
            scrollCoords[this.state.segment] = e.target.scrollTop;
            this.setState({scrollCoords: scrollCoords});
        }

        var diff = e.target.scrollTop + e.target.offsetHeight - e.target.scrollHeight

        if (diff > -2 && diff < 2) {
            let page = Object.assign({}, this.state.page);
            if (page[this.state.segment] < 100 && !this.state.loadingAllProducts) {
                page[this.state.segment] += 1;

                if (page[this.state.segment] === 10) {
                    this.tracking('Revery.ScrolledTen', 'Webpage hit page 10 on changing room', "ScrolledTen", "Webpage");
                }
                this.tracking('Revery.NewPage', 'Webpage user scrolled to bottom of products on changing room', "NewPage", "Webpage",);

                this.setState({
                    page: page,
                    loadingAllProducts: true
                }, () => {
                    this.fetchNewPage(false, false, "none", this.state.showSaved, true);
                });

            }
        }
    }

    trackProductClick(e) {
        this.tracking('Revery.ProductClick', 'Webpage user was redirected to product through changing room', "ProductClick", "Webpage", "productID", e.currentTarget.name);
    }

    onSaveClick(e) {
        var savedProducts = Object.assign({}, this.state.savedProducts);
        var allSavedProducts = this.state.allSavedProducts.slice();
        var products = savedProducts[this.state.gender][this.state.segment];
        let product = e.currentTarget.getAttribute("name");
        let isSave = (!allSavedProducts.includes(product)) ? true : false;

        // const data = JSON.stringify({
        //     "user_id": this.visitorID,
        //     "product_id": product,
        //     "is_save": isSave,
        // });
        // fetch(
        //     this.endpoint + '/interactivedemo_hr/save_product',
        //     {
        //         method: 'POST',
        //         headers: this.getAuthenticationHeader(true),
        //         body: data
        //     }).then(response => {
        //     // console.log(response);
        // });

        isSave ? products.push(product) : products.splice(products.indexOf(product), 1);
        isSave ? allSavedProducts.push(product) : allSavedProducts.splice(allSavedProducts.indexOf(product), 1);

        savedProducts[this.state.gender][this.state.segment] = products
        this.setState({
            savedProducts: savedProducts,
            allSavedProducts: allSavedProducts
        })

    }

    toggleTucked() {
        if (this.state.isLoadingImg || this.state.isLoadingImgCustomize) {
            return
        }
        this.setState({toggleTucked: (this.state.toggleTucked) ? false: true}, ()=>{
            this.fetchNewTryonModelImage(
                this.state.wearingCategories,
                this.state.wearingProducts,
                this.state.model_id
            );
        })
    }

    renderProducts() { // render the selected garment page
        if (this.state.segment === "background") {
            return this.renderBackgrounds();
        } else if (this.state.segment === "shoes") {
            return this.renderShoes();
        } else if (this.state.segment === "faces") {
            return this.renderFaces();
        }
        let element = document.getElementById(this.state.segment);
        if (element != null) {
            element.checked = true;
        }
        var products = (this.state.showSaved ? this.state.savedProducts : this.state.products)

        if (this.state.productIsLoadingFirst) { //first time switching to a new section, remove all garments and re-render to avoid confusion with previous garments still on page
            this.setState({
                productIsLoadingFirst: false
            });
        } else {
            if (products[this.state.gender][this.state.segment].length === 0 && this.state.showSaved) {
                return ([
                    <div style={{"margin-top":"2%"}}>Save items in this category to your dressing room!</div>
                ])
            }
            return ([
                products[this.state.gender][this.state.segment].map((product) => (
                    <div name={product}
                         className={this.state.itemClass[product] + (this.state.wearingProducts.includes(product) ? " selected" : "")}
                         id={"revery_" + product}>
                        {/*src={this.state.itemUrl[product]}*/}
                        <img name={product} className="productImg" src={"https://media.revery.ai/revery_client_images/" + product + "/in_origin.png"}
                             onLoad={this.setAllProductsLoadedFalse} onClick={this.garmentClickHandler}/>
                        <div className={"saveIcon"} name={product} onClick={this.onSaveClick}>
                            {this.state.allSavedProducts.includes(product) ?
                                <div><img className="heart-icon" src={heart_fill}/></div> :
                                <div><img className="heart-icon" src={heart}/></div>}
                        </div>
                        <div className={"productNameDiv"}>
                            {/*<a className="product" name={product} href={this.state.itemPage[product]} target="_blank" onClick={this.trackProductClick}>*/}
                                <p className="product" name={product} target="_blank">
                                {(this.state.itemBrand[product] !== null) ? truncate(this.state.itemBrand[product].toUpperCase()) : "No Brand"}
                            </p>
                        </div>
                    </div>
                ))
            ])
        }
    }

    async backgroundClickHandler(e) {
        let bg_url = e.currentTarget.getAttribute("src");
        if (bg_url == "empty") {
            this.setState({background_url: ""}, ()=>{
                this.fetchNewTryonModelImage(this.state.wearingCategories, this.state.wearingProducts, this.state.model_id)
            })
        } else {
            this.backgroundImageGenerator(this.state.modelFile, bg_url);
        }
    }

    async backgroundImageGenerator(modelFile, bg_url){
        this.setState({
            isLoadingImg: true,
        });
        const data = JSON.stringify({
            "model_url": "https://media.revery.ai/generated_model_image/" + modelFile + ".png",
            "bg_url": bg_url,
        });

        await fetch(
            'http://3.137.127.110:2999/composite_images',
            // this.endpoint + '/composite_images',
            {
                method: 'POST',
                headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
                body: data
            }).then(response => response.json()).then((body) => {
            if (body.combined_file !== null) {
                this.setState({
                    modelFile: modelFile,
                    displayFile: "https://media.revery.ai/" + body.combined_file.composite_file,
                    isLoadingImg: false,
                    background_url: bg_url
                })
            }
        })
        this.setState({
            isLoadingImg: false,
        })
    }

    renderBackgrounds() {
        return ([
            <div src="empty" className="productImgContainer" onClick={this.backgroundClickHandler}>
                Click To Remove Background
            </div>,
            this.state.backgrounds.map((background_url) => (
                <div name={background_url} className="productImgContainer">
                    <img name={"https://media.revery.ai/composite_images/backgrounds/" + background_url} className="productImg"
                         src={"https://media.revery.ai/composite_images/backgrounds/" + background_url} onClick={this.backgroundClickHandler}/>
                </div>
            ))
        ])
    }

    renderShoes() {
        let shoeModelsList = this.state.shoeModels[this.state.gender];
        return ([
            shoeModelsList.map((model, i) => (
                <div name={model} className={"productImgContainer " + (this.state.shoes_id === model ? "selected":"")}
                     onClick={this.shoeClickHandler}>
                    <img className="productImg" name={model} src={this.state.modelShoeUrls[model]}/>
                </div>
            ))
        ])
    }

    renderFaces() {
        let faceIdsList = this.state.faceIds[this.state.gender];
        return ([
            faceIdsList.map((face, i) => (
                <div name={face} className={"productImgContainer " + (this.state.face_id === face ? "selected":"")}
                     onClick={this.faceClickHandler}>
                    <img className="productImg" name={face}
                         src={"https://media.revery.ai/revery_client_models/" + face + "/ou_aligned.png"}/>
                </div>
            ))
        ])
    }

    toggleModels(e) {
        this.state.toggleModels ? this.setState({toggleModels: false}) : this.setState({toggleModels: true});
    }

    toggleGarments(e) {
        this.state.toggleGarments ? this.setState({toggleGarments: false}) : this.setState({toggleGarments: true});
        this.toggleScrollUpFalse();
    }

    chooseGender(e) {
        if (!this.state.isPageLoading) {
            this.tracking('Revery.SwitchedGender', 'Webpage switched genders', "SwitchedGender", "Webpage", "gender", e.currentTarget.name);
            if (e.currentTarget.name !== this.state.gender) {
                let default_male_shoe = "model_13903163";
                let default_female_shoe = "model_13136326";
                let default_female_top = "symbol_186303"
                let default_female_bottom = "symbol_188928"
                let default_male_top = "symbol_184875"
                let default_male_bottom = "symbol_188783"
                let wearingCategories = ["tops", "bottoms"];
                let wearingProducts = [default_male_top, default_male_bottom];
                if (e.currentTarget.name == "male") {
                    let shoe_model_ids = Object.assign({},this.state.shoeModels);
                    let index = shoe_model_ids["male"].indexOf(default_male_shoe);
                    shoe_model_ids["male"].splice(index, 1);
                    shoe_model_ids["male"].unshift(default_male_shoe);
                    this.setState({shoe_model_ids: shoe_model_ids})
                } else {
                    wearingProducts = [default_female_top, default_female_bottom];
                }
                this.setState({
                        showSaved: false,
                        wearingCategories: wearingCategories,
                        wearingProducts: wearingProducts,
                        gender: e.currentTarget.name,
                        shoes_id: e.currentTarget.name == "male" ? default_male_shoe : default_female_shoe,
                        isPageLoading: true,
                        face_id: null,
                    }, () => {
                        this.fetchProducts();
                    }
                )
            }
        }
    }

    zoom(e) {
        if (this.client === "YOOX"){
            let img = e.currentTarget
            img.className = (img.className == "modelImg") ? "modelImg zoom" : "modelImg";
        }
    }

    zoomLeave(e) {
        if (this.client === "YOOX") {
            let img = e.currentTarget
            img.className = "modelImg";
        }
    }

    renderImg() { // render generated image
        let element = document.getElementById(this.state.gender);

        if (element != null) {
            element.checked = true;
        }
        return ([
            <a>
                <img className="modelImg" src={this.state.displayFile} onLoad={this.setLoadingFalse} onClick={this.zoom} onMouseLeave={this.zoomLeave}/>
            </a>
        ])
    }

    renderCover() {
        const logo = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/logos/main_logo_black.png"
        const background = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/website_demo/background.png"

        const {progress} = this.state;
        return ([
            <div className="frame" id="reveryFrame">
                <div className="loadingInitialCover">
                    <img name="loadingCoverImg" className="loadingCoverImg" src={background}/>
                </div>
                <div className="loadingCoverText">
                    <p className={"loadingHeading"}>Your brand here</p>
                    <progress className="progressBar" value={progress} max="100"></progress>
                    <p className={"poweredBy"}>Powered By</p>
                    <a href="https://revery.ai" target="_blank">
                        <img name="loadingLogo" className="loadingLogo" src={logo}/>
                    </a>
                </div>

            </div>
        ]);
    }

    renderGarmentsFrameMobile() {
        const {isLoading} = this.state;
        const tops_image_clear = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/tops.svg";
        const outerwear_image_clear = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/outerwear.svg";
        const bottoms_image_clear = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/bottoms.svg";
        const dress_image_clear = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/dress.svg";
        const shoes_image_clear = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/shoes.svg";

        let img = tops_image_clear;
        let segment = this.state.segment;
        if (segment === "tops") {
            img = tops_image_clear;
        } else if (segment === "bottoms") {
            img = bottoms_image_clear;
        } else if (segment === "outerwear") {
            img = outerwear_image_clear;
        } else if (segment === "allbody") {
            img = dress_image_clear;
        } else {
            img = shoes_image_clear;
        }

        let segment_text = "";
        if (segment === "tops") {
            segment_text = "Tops";
        } else if (segment === "bottoms") {
            segment_text = "Bottoms";
        } else if (segment === "outerwear") {
            segment_text = "Outerwears";
        } else if (segment === "allbody") {
            segment_text = "Dresses";
        } else {
            segment_text = "Shoes";
        }

        return [
            this.state.toggleGarments ?
                <div className={"toggleGarmentContainer mobile expanded"} >
                    <div className={"toggleGarmentButton expanded"} onClick={this.toggleGarments}>
                        Back
                    </div>
                </div>
                :
                <div className={"toggleGarmentContainer mobile"} >
                    <div className={"toggleGarmentTopContainer"}>
                        <div className={"toggleGarmentLeftContainer"}>
                            <div className={"toggleGarmentIcon mobile"} >
                                <p>{segment_text}</p>
                            </div>
                            <div className={"toggleGarmentIcon mobile view_all"} onClick={this.toggleGarments}>
                                <p style={{"color": "white"}}>View all</p>
                            </div>
                        </div>
                        {/*<div className={"toggleGarmentIcon mobile change"} onClick={this.gotoPageOne}>*/}
                        {/*    <p>Change model</p>*/}
                        {/*</div>*/}
                    </div>
                    {(this.state.segment === "tops") ?
                        <div className={"toggleTuck mobile"}>
                            <form className={"saleForm"}>
                                <input className="checkBox mobile" type="checkbox" id="vehicle1" name="vehicle1" value="Bike"
                                       checked={(this.state.toggleTucked ? "checked" : "")} onChange={this.toggleTucked}/>
                                <label htmlFor="vehicle1" className={"tuckText"}> <p>Tuck</p> </label>
                            </form>
                        </div> : ""}
                </div>,
                <div id="garmentOptions" className={"garmentOptions " + ((this.state.mobile) ? "mobile ":"") + ((this.state.toggleGarments) ? "expanded ":"") + (this.state.toggleScrollUp ? "scrollUp" : "")}
                     onScroll={this.handleScroll}>
                    {this.renderProducts()}
                    <div className="smallLoaderDiv">
                        <div className={isLoading ? "smallLoader" : ""}></div>
                    </div>
                </div>
        ]
    }

    renderGarmentsFrame() {
        const {isLoading} = this.state;

        let segment_text = "";
        let segment = this.state.segment;
        if (segment === "tops") {
            segment_text = "Tops";
        } else if (segment === "bottoms") {
            segment_text = "Bottoms";
        } else if (segment === "outerwear") {
            segment_text = "Outerwears";
        } else if (segment === "allbody") {
            segment_text = "Dresses";
        } else if (segment === "shoes"){
            segment_text = "Shoes";
        } else {
            segment_text = "Faces"
        }

        return [
            this.state.toggleGarments ?
                <div className={"toggleGarmentContainer expanded"} >
                    <div className={"toggleGarmentButton expanded"} onClick={this.toggleGarments}>
                        Back
                    </div>
                </div>
                :
                <div className={"toggleGarmentContainer"} >
                    <div className={"toggleGarmentIcon"} >
                        <p>
                            {segment_text}
                        </p>
                    </div>
                    {(this.state.segment === "tops" || this.state.segment === "bottoms") ?
                        <div className={"toggleTuck"}>
                            <form className={"saleForm"}>
                                <input className="checkBox" type="checkbox" id="vehicle1" name="vehicle1" value="Bike"
                                       checked={(this.state.toggleTucked ? "checked" : "")} onChange={this.toggleTucked}/>
                                {/*<label htmlFor="vehicle1"> Tuck </label>*/}
                                <label htmlFor="vehicle1" className={"tuckText"}> <p>Tuck</p> </label>
                            </form>
                        </div> : "" }
                </div>,
                <div id="garmentOptions" className={"garmentOptions " + ((this.state.mobile) ? "mobile ":"") + ((this.state.toggleGarments) ? "expanded":"")} onScroll={this.handleScroll}>
                    {this.renderProducts()}
                    <div className="smallLoaderDiv">
                        <div className={isLoading ? "smallLoader" : ""}></div>
                    </div>
                </div>
        ]
    }

    gotoPageOne() {
        this.setState({step: 1})
    }

    renderPage() {
        const {
            isLoading,
            isPageLoading,
            isLoadingImg,
            isLoadingImgCustomize,
        } = this.state;

        let watermark = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/logos/main_logo_black.png"

        return ([
            <div className="frame" id="reveryFrame" name="https://revery.ai">
                <div className="modelFrame">
                    <div className="modelImgContainer">
                        {/*<div className={"changeModelDiv"}>*/}
                        {/*    <div className={"changeModelButton"} onClick={this.gotoPageOne}>*/}
                        {/*        Change model*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {this.renderImg()}
                        {/*<div className={"changeModelDiv"}>*/}
                        {/*    <div className={"changeModelButton"} onClick={this.gotoPageOne}>*/}
                        {/*        Change model*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"watermarkDiv"}>*/}
                        {/*    <img className="watermark" src={watermark}/>*/}
                        {/*</div>*/}
                    </div>
                    <div className={(isLoadingImg || isPageLoading || isLoadingImgCustomize) ? "loadingCover" : "loadingCoverHidden"}>
                        <div className="loader"></div>
                    </div>
                </div>
                <div className={"rightFrame"}>
                    <div className={"garmentFrame " + ((this.state.mobile) ? "mobile ":"") + ((this.state.toggleGarments) ? "expanded":"")} id="garmentFrame">
                        <div className={"titleDiv"}>
                            <div className={"title"}>
                                Virtual Dressing Room
                            </div>
                        </div>
                        <div className={"segmentFrame"}>
                            <Segments
                                String={this.state.String}
                                gender={this.state.gender}
                                mobile={this.state.mobile}
                                segment={this.state.segment}
                                wearingCategories={this.state.wearingCategories}
                                segmentControl={this.segmentControl}
                                removeOuterwear={this.removeOuterwear}
                            />
                        </div>
                        {this.renderGarmentsFrame()}
                    </div>
                </div>
            </div>
        ]);
    }

    renderMobile() {
        const {
            isLoading,
            isPageLoading,
            isLoadingImg,
            isLoadingImgCustomize,
        } = this.state;

        let watermark = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/logos/main_logo_black.png"

        return ([
            <div className="frame mobile" id="reveryFrame" name="https://revery.ai" >
                <div className={"titleDiv mobile"} onClick={this.toggleScrollUpFalse}>
                    <div className={"title mobile"}>
                        Virtual Dressing Room
                    </div>
                </div>
                {/*<div className={"changeModelDiv mobile"}>*/}
                {/*    <div className={"changeModelButton mobile"} onClick={this.gotoPageOne}>*/}
                {/*        Change model*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="modelFrame mobile" onClick={this.toggleScrollUpFalse}>
                    <div className="modelImgContainer">
                        {this.renderImg()}
                        <div className={"watermarkDiv"}>
                            <img className="watermark" src={watermark}/>
                        </div>
                    </div>
                    <div className={(isLoadingImg || isPageLoading || isLoadingImgCustomize) ? "loadingCover mobile" : "loadingCoverHidden"}>
                        <div className="loader mobile"></div>
                    </div>
                </div>
                <div className={"segmentFrame mobile"}>
                    <Segments
                        String={this.state.String}
                        gender={this.state.gender}
                        mobile={this.state.mobile}
                        segment={this.state.segment}
                        wearingCategories={this.state.wearingCategories}
                        segmentControl={this.segmentControl}
                        removeOuterwear={this.removeOuterwear}
                    />
                </div>
                <div className={"garmentFrame " + ((this.state.mobile) ? "mobile ":"") + ((this.state.toggleGarments) ? "expanded ":"") + (this.state.toggleScrollUp ? "scrollUp" : "")} id="garmentFrame">
                    {this.renderGarmentsFrameMobile()}
                </div>
            </div>
        ]);
    }

    renderAuthentication() {
        return ([
            <form className="QALogin" style={{"text-align":"center"}} onSubmit={this.submitAuthentication}>
                <label htmlFor="qaclient">Client:</label>
                <input type="text" id="qaclient" name="qaclient"></input>
                <label htmlFor="qaname">Name:</label>
                <input type="text" id="qaname" name="qaname"></input>
                <label htmlFor="qakey">Secret Key:</label>
                <input type="password" id="qakey" name="qakey"></input>
                <input type="submit" value="Submit" />
            </form>
        ])
    }

    toggleWidget() {
        var frame = document.getElementsByClassName("frame")[0];
        frame.style.display = (frame.style.display === "block") ? "none" : "block";
    }


    render() {
        const {isLoadingCover, step, mobile, authenticated} = this.state;
        // if (isLoadingCover) {
        //     return([<div>
        //         {this.renderCover()}
        //     </div>]);
        // }
        // else {
        if (authenticated === true) {
            if (step == 1) {
                return <PageOne
                    endpoint={this.endpoint}
                    mobile={this.state.mobile}
                    getAuthenticationHeader={this.getAuthenticationHeader}
                    login={this.login}
                    client={this.client}
                    gender={this.state.gender}
                    isPageLoading={this.state.isPageLoading}
                    skinTone={this.state.skinTone}
                    skinToneModelFilesDict={this.state.skinToneModelFilesDict}
                    savedProducts={this.state.savedProducts}
                    skinToneModels={this.state.skinToneModels}
                    // skinToneModelFiles={this.state.ski}
                    model_id={this.state.model_id}
                    setSkinTone={this.setSkinTone}
                    modelClickHandler={this.modelClickHandler}
                    chooseGender={this.chooseGender}
                />
            }
            return ([<div>
                {mobile? this.renderMobile(): this.renderPage()}
                {/*{this.renderMobile()}*/}
            </div>]);
        } else if (authenticated === false) {
            return ([
                <div>
                    {this.renderAuthentication()}
                </div>
            ])
        } else {
            return ([<div></div>])
        }
        // }
    }
}

export default App;
