import React, { Component } from "react";

class Segments extends Component {
    constructor(props) {
        super(props);
        this.renderSegments = this.renderSegments.bind(this);
        this.toggleUser = this.toggleUser.bind(this);
    }

    renderSegments() {
        const tops_image_clear = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/tops.svg";
        const outerwear_image_clear = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/outerwear.svg";
        const bottoms_image_clear = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/bottoms.svg";
        const dress_image_clear = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/dress.svg";
        const shoes_image_clear = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/shoes.svg"
        const face_image_clear = "https://revery-integration-tools.s3.us-east-2.amazonaws.com/images/face.svg"

        let segment = this.props.segment;
        if (this.props.gender === "male") {
            return ([
                <div className={"selectCategoryContainer text"}>
                    <p>{(this.props.mobile) ? "Customize" : ""}</p>
                </div>,
                <div name="tops"
                     className={"selectCategoryContainer tops " + (segment === "tops" ? "select" : "")}
                     value="tops" id="tops" onClick={this.props.segmentControl}>
                    <div className={"selectCategoryButton"}>
                        <img src={tops_image_clear}></img>
                    </div>
                </div>,
                <div name="bottoms"
                     className={"selectCategoryContainer bottoms " + (segment === "bottoms" ? "select" : "")}
                     value="bottoms" id="bottoms" onClick={this.props.segmentControl}>
                    <div className={"selectCategoryButton"}>
                        <img src={bottoms_image_clear}></img>
                    </div>
                </div>,
                <div name="outerwear"
                     className={"selectCategoryContainer outerwear " + (segment === "outerwear" ? "select" : "")}
                     value="outerwear" id="outerwear" onClick={this.props.segmentControl}>
                    <div className={"selectCategoryButton"} id={"outerwear"}>
                        <img src={outerwear_image_clear}></img>
                    </div>
                    {this.props.wearingCategories.includes("outerwear") ?
                        <div className={"removeOuterwear"} id={"outerwear"}>
                            <div className={"removeOuterwearButton"} id={"removeOuterwear"} onClick={this.props.removeOuterwear}>
                                &times;
                            </div>
                        </div> : ""}
                </div>,
                <div name="shoes" className={"selectCategoryContainer " + (segment === "shoes"  ? "select" : "")}  value="shoes" id="shoes" onClick={this.props.segmentControl}>
                    <div className={"selectCategoryButton"}>
                        <img src={shoes_image_clear}></img>
                    </div>
                </div>,
                <div name="faces" className={"selectCategoryContainer " + (segment === "faces"  ? "select" : "")}  value="faces" id="faces" onClick={this.props.segmentControl}>
                    <div className={"selectCategoryButton face"}>
                        <img src={face_image_clear}></img>
                    </div>
                </div>,
                //     <div name="bags" className={"selectCategoryContainer " + (segment === "bags"  ? "select" : "")}  value="bags" id="bags" onClick={this.segmentControl}>
                //   <p className="selectCategory" id="bags">{this.props.String.bags.toUpperCase()}</p>
                // </div>,
            ])
        } else {
            return ([
                <div className={"selectCategoryContainer text"}>
                    {/*<p>Customize</p>*/}
                    <p>{(this.props.mobile) ? "Customize" : ""}</p>
                </div>,
                <div name="tops"
                     className={"selectCategoryContainer tops female " + (segment === "tops" ? "select" : "")}
                     value="tops" id="tops" onClick={this.props.segmentControl}>
                    <div className={"selectCategoryButton"}>
                        <img src={tops_image_clear}></img>
                    </div>
                </div>,
                <div name="bottoms"
                     className={"selectCategoryContainer bottoms female " + (segment === "bottoms" ? "select" : "")}
                     value="bottoms" id="bottoms" onClick={this.props.segmentControl}>
                    <div className={"selectCategoryButton"}>
                        <img src={bottoms_image_clear}></img>
                    </div>
                </div>,
                <div name="outerwear"
                     className={"selectCategoryContainer outerwear female " + (segment === "outerwear" ? "select" : "")}
                     value="outerwear" id="outerwear" onClick={this.props.segmentControl}>
                    <div className={"selectCategoryButton"} id={"outerwear"}>
                        <img src={outerwear_image_clear}></img>
                    </div>
                    {this.props.wearingCategories.includes("outerwear") ?
                        <div className={"removeOuterwear"} id={"outerwear"}>
                            <div className={"removeOuterwearButton"} id={"removeOuterwear"} onClick={this.props.removeOuterwear}>
                                &times;
                            </div>
                        </div> : ""}
                </div>,
                <div name="allbody"
                     className={"selectCategoryContainer allbody female " + (segment === "allbody" ? "select" : "")}
                     value="allbody" id="allbody" onClick={this.props.segmentControl}>
                    <div className={"selectCategoryButton"}>
                        <img src={dress_image_clear}></img>
                    </div>
                </div>,
                <div name="shoes" className={"selectCategoryContainer " + (segment === "shoes"  ? "select" : "")}  value="shoes" id="shoes" onClick={this.props.segmentControl}>
                    <div className={"selectCategoryButton"}>
                        <img src={shoes_image_clear}></img>
                    </div>
                </div>,
                <div name="faces" className={"selectCategoryContainer " + (segment === "faces"  ? "select" : "")}  value="faces" id="faces" onClick={this.props.segmentControl}>
                    <div className={"selectCategoryButton face"}>
                        <img src={face_image_clear}></img>
                    </div>
                </div>,
                //     <div name="bags" className={"selectCategoryContainer female " + (segment === "bags"  ? "select" : "")}  value="bags" id="bags" onClick={this.segmentControl}>
                //   <p className="selectCategory" id="bags">{this.props.String.bags.toUpperCase()}</p>
                // </div>,
            ])
        }
    }

    toggleUser(e) {
        this.props.gotoMainPage(e);
    }

    render() {
        return([
            this.renderSegments()
        ])
    }
}

export default Segments;
